import { useDispatch, useSelector } from "react-redux";
import { authActions } from "saur-states-management/lib/states/authState";
import { getAuthenticatedUser } from "saur-states-management/lib/selectors/authSelectors";
import { isFeatureEnabled } from "saur-states-management/lib/selectors/settingsSelectors";
import { States } from "saur-states-management";
const useLogout = () => {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector(getAuthenticatedUser);
  const state = useSelector((s: States) => s);
  const logoutV2Enabled = isFeatureEnabled(state, "User-Logout")
  
  const logout = () => {
    if (logoutV2Enabled) {
      if (authenticatedUser?.clientId) {
        dispatch(authActions.logoutV2(authenticatedUser?.clientId));
      }
    } else {
      dispatch(authActions.logout());
    }
  };

  return logout;
};

export default useLogout;
