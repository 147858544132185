import { css } from 'styled-components'
import { device } from 'saur-design-sys-web/lib/Theme'

const onlyForDesktop = css`
    @media ${device.mobile} {
        display: none;
    }
    @media ${device.tablet} {
        display: flex;
    }
`

const onlyForMobile = css`
    @media ${device.mobile} {
        display: flex;
    }
    @media ${device.tablet} {
        display: none;
    }
`

const onlyForLargeScreen = css`
    @media ${device.mobile} {
        display: none;
    }
    @media ${device.tablet} {
        display: none;
    }
    @media ${device.desktop} {
        display: flex;
    }
`

export {
    onlyForDesktop,
    onlyForMobile,
    onlyForLargeScreen
}