import React, { FC, useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getLanguage } from "saur-states-management/lib/selectors/settingsSelectors";

import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import Word from "saur-design-sys-web/lib/Atoms/Word/Word";
import AppLogo from "saur-design-sys-web/lib/Atoms/AppLogo/AppLogo";
import { device, colors } from "saur-design-sys-web/lib/Theme";
import { ThemeType } from "saur-design-sys-web/lib/BrandsConfig";
import { createGlobalStyle } from "styled-components";
import { onlyForDesktop, onlyForMobile } from "../common/styles";
import { usePath } from "../common/navigation";
import { langTypes } from "saur-states-management/lib/i18n";
import { authActions } from "saur-states-management/lib/states/authState";
import { settingsActions } from "saur-states-management/lib/states/settingsState";
import useRequestScript from "../common/useRequestScript";
import UserLight from "saur-states-management/lib/models/userLight";
import { persistor } from "../configStore";

type LoginScreenProps = {};

const CCLLoadingScreen: FC<LoginScreenProps> = () => {
  useRequestScript(["login-script"]);
  const lang = useSelector(getLanguage) as langTypes;
  const dispatch = useDispatch();
  const history = useHistory();
  const { getPath } = usePath();
  const [ssoRef, setSsoRef] = useState<any>(null)
  const [loginInformation, setLoginInformation] = useState<any>(null)

  useEffect(() => {
    setSsoRef(document.getElementById("sso-ccl"))
  }, []);

  useEffect(() => {
    if (ssoRef && !loginInformation) {
      window.addEventListener("message", (event) => {
        if (event.origin !== process.env.REACT_APP_CCL_URL)
          return;

        if (event?.data?.command === 'init' && event.source)
          event.source.postMessage({ command: 'acknowledged' }, { targetOrigin: event.origin });
        else if (event.data && !loginInformation) {
          setLoginInformation(JSON.parse(event.data))
        }
      });
    }
  }, [ssoRef, loginInformation])

  const goToDashboard = useCallback((e: any) => {
    persistor.persist();
    dispatch(authActions.setToken(e.detail.token));
    const user = new UserLight({
      clientId: e.detail.clientId,
      firstName: e.detail.firstName,
      lastName: e.detail.lastName,
      defaultContractId: e.detail.defaultContractId,
      defaultDeliveryPointId: e.detail.defaultDeliveryPointId,
      defaultContract: e.detail.defaultContract,
      defaultSectionId: e.detail.defaultSectionId,
      defaultSectionIds: [e.detail.defaultSectionId],
      clientReference: e.detail.clientReference,
      defaultCustomerAccountId: e.detail.defaultCustomerAccountId,
      isFamilyMode: false,
      isEligibleCoachConso: false,
      isEligibleNewFeaturesMessage: false,
      pairingTechnologyCode: e.detail.pairingTechnologyCode,
      isMultiTargetAlertsEnabled: e.detail.isMultiTargetAlertsEnabled,
      defaultFamilyId: ""
    })

    dispatch(authActions.setAuthentifiedUser(user));
    dispatch(settingsActions.getUserBrandParameters());
    dispatch(authActions.setExpireDateToken(e.detail.expireDate));
    dispatch(authActions.setRefreshToken(e.detail.refreshToken));
    history.push(`/${lang}${getPath("dashboard")}`);
  }, [dispatch, getPath, history, lang])

  useEffect(() => {
    if (loginInformation && loginInformation.token?.length && loginInformation.clientId?.length) {
      sessionStorage.setItem("sso-connection", JSON.stringify(true));
      goToDashboard({ detail: loginInformation })
    }
  }, [loginInformation, goToDashboard])

  return (
    <Container className="CCLLoadingScreen">
      <Styles />
      <Container className="LoginScreen-main-content">
        <Container className="LeftPanelRow">
          <Container className="LeftPanel">
            <AppLogo type="lighten" content="login" />
            <Container className="LeftPanelContainerTitle">
              <Word
                className="LeftPanelTitle"
                value="Tentative de connexion en accès CCL"
                textStyle="titleSemiLarge"
                variant="h1"
              />
            </Container>
            <Word
              className="LeftPanelDescWord"
              value="Veuillez patienter..."
              textStyle="normalReg"
              variant="p"
            />
            <img
              alt=""
              className="LeftPanelIllustration"
              src="/assets/login-visuel.svg"
            />
          </Container>
        </Container>
        <Container className="RightPanelRow">
          <Container className="RightPanel">
            <Container className="RightPanelContent">
              <Word
                className="mobile-ccl-access-title"
                value="Tentative de connexion en accès CCL"
                textStyle="titleSemiLarge"
                variant="h1"
              />
              <Word
                className="mobile-ccl-access-description"
                value="Veuillez patienter..."
                textStyle="normalReg"
                variant="p"
              />
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

const Styles = createGlobalStyle`
.sds-reset-style{
    .CCLLoadingScreen {
        height: 100vh;
        .LoginScreen-main-content {
            flex: 1;
            @media ${device.mobile} {
                flex-direction: column;
            }
            @media ${device.tablet} {
                flex-direction: row;
                align-items: stretch;
            }
            .LeftPanelContainerTitle {
            ${onlyForDesktop}
            justify-content: center;
            align-items: center;
        }
        .BackButtonMobile {
            ${onlyForMobile}
            position: absolute;
            left: 4%;
            top: 50%;
            transform: translateY(-50%);
            svg {
                width: 20px;
                height: 20px;
                path {
                    fill: ${colors.white}
                }
            }
        }
        .BackButtonDesktop {
            ${onlyForDesktop}
            .sds-button {
                background-color: ${colors.white};
            }
            .sds-word {
                color: ${colors.black}
            }
        }
        .LeftPanelRow {
            position: relative;
            background-color: ${(props: ThemeType) =>
    props.theme.colors.primaryLight};
            @media ${device.mobile} {
                width: 100%;
                height: 51px;
            }
            @media ${device.tablet} {
                width: 100%;
                height: auto;
            }
        }
        .LeftPanel {
          flex: 1;
          align-self: stretch;
            background-color: ${(props: ThemeType) =>
    props.theme.colors.primaryDark};
            @media ${device.mobile} {
                justify-content: center;
                flex-direction: row;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 0px;
                height: none;
            }
            @media ${device.tablet} {
                justify-content: space-around;
                align-items: center;
                flex-direction: column;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 60px;
            }
        }
        .LeftPanelTitle {
            ${onlyForDesktop};
            text-align: center;
            color: ${(props: ThemeType) =>
    props.theme.texts.lightColor};
            &:first-child {
                margin-bottom: 10px;
            }
        }
        .LeftPanelDescWord {
            padding: 0 8%;
            color: ${(props: ThemeType) =>
    props.theme.texts.lightColor};
            ${onlyForDesktop}
        }
        .MobileTitle {
            color: ${(props: ThemeType) =>
    props.theme.texts.lightColor};
            text-align: center;
            ${onlyForMobile}
        }
        .LeftPanelIllustration {
            max-width: 380px;
            margin-bottom:100px;
            ${onlyForDesktop}
        }
        .RightPanelRow {
            position: relative;
            text-align:center;
            
            background-color: ${(props: ThemeType) =>
    props.theme.colors.primaryDark};
            align-items: stretch;
            @media ${device.mobile} {
                width: 100%;
                height: calc(100vh - 51px);
            }
            @media ${device.tablet} {
                width: 50%;
                height: 100vh;
                display:none
            }
        }
        .mobile-ccl-access-title{
          margin-bottom:50px;
        }
        .RightPanel {
            flex: 1;
            align-self: stretch;
            @media ${device.mobile} {
                border-top-right-radius: 30px;
                border-top-left-radius: 0px;
                padding: 4% 6%;
                height: none;
            }
            @media ${device.tablet} {
                padding: 0 8%;
                border-top-right-radius: 0px;
                border-top-left-radius: 60px;
            }
            background-color: ${(props: ThemeType) =>
    props.theme.colors.primaryLight};
        }
        .RightPanelContent {
            flex: 1;
            justify-content: center;
            padding: 20px 0;
        }
        .ImageBadge {
            width: 90px;
            height: 44px;
            &:first-child {
                margin: 0 30px;
            }
        }
        .RightPanelFooter {
            ${onlyForDesktop}   
            flex-direction: row;
            margin-bottom: 8%;
            align-items: center;
        }
        .BadgesContainer {
            flex-direction: row;
            align-self: flex-end;
            justify-content: center;
        }
        }

        .LoginScreen-disco-modal {
          .card-header {
            display: none;
          }
        }
    }
}
`;

export default CCLLoadingScreen;
