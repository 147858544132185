import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { States } from "saur-states-management";
import Consumer from "saur-states-management/lib/models/consumer";
import { getAuthenticatedUser } from "saur-states-management/lib/selectors/authSelectors";
import { getConsumer } from "saur-states-management/lib/selectors/consumerSelector";
import { isFeatureEnabled } from "saur-states-management/lib/selectors/settingsSelectors";
import { authActions } from "saur-states-management/lib/states/authState";
import { deliveryPointActions } from "saur-states-management/lib/states/deliveryPointState";
import useEventListener from "../common/useEventListener";


/**
 * Custom hook for managing event listeners for specific widgets based on user authentication
 *  @returns nothing is retunred
 */
const useWidgetsEventListeners = () => {
  const [contractWidgetRef, setContractWidgetRef] = useState<Element | null>(
    null
  );
  const [eventListenerExist, setEventListenerExist] = useState<boolean>(false);

  const dispatch = useDispatch();

  const authenticatedUser = useSelector(getAuthenticatedUser);
  const consumer = useSelector(getConsumer) as Consumer;
  const isInterventionHistorique = useSelector((s: States) =>
    isFeatureEnabled(s, "Intervention-Afficher-Historique")
  );

  const consumerIsContractTerminated =
    authenticatedUser?.defaultContract?.isContractTerminated;

  // Use Effect
  useEffect(() => {
    setContractWidgetRef(document.querySelector("contract-widget"));
  }, []);

  useEventListener(
    "select-family-on-click-event",
    (e) => {
      const selectFamilyEvent = e as CustomEvent;
      if (selectFamilyEvent.detail.sectionSubscriptionIds)
        dispatch(
          authActions.setAuthentifiedUserFamily(selectFamilyEvent.detail)
        );
    },
    contractWidgetRef
  );

  const widgetsList = useMemo(
    () => [
      "create-account-widget",
      "overconsuming-alerts-list",
      "forget-password-widget",
      "user-information-widget",
      "update-email-result-widget",
      "login-widget",
      "widget-exchanges",
      "coachconso-widget",
      "widget-articles",
      "header-payments",
      "next-payment",
      "invoice-history",
      "payments-history",
      "my-services",
      "last-invoice-widget",
      "last-meter-widget",
      "consumption-graph",
      "widget-delivery-info",
      "contact-us-widget",
      "contract-widget",
      "contact-widget",
      "widget-exchanges-switch",
    ],
    []
  );

  useEffect(() => {
    if (authenticatedUser) {
      widgetsList.forEach((widget) => {
        const ref: any = document.querySelector(widget);
        if (ref) {
          ref.sectionsubscriptionid = authenticatedUser.defaultSectionId;
          ref.sectionsubscriptionids = authenticatedUser.defaultSectionIds;
          ref.customeraccountid = authenticatedUser.defaultCustomerAccountId;
          ref.refclient = authenticatedUser.clientReference;
          ref.clientid = authenticatedUser.clientId;
          ref.clientreference = authenticatedUser.clientReference;
          ref.clientfirstname = consumer?.firstName ?? "";
          ref.familymode = authenticatedUser.isFamilyMode;
          ref.familyid = authenticatedUser.defaultFamilyId;
          ref.pairingtechnologycode = authenticatedUser.pairingTechnologyCode;
        }
      });
    }
  }, [
    widgetsList,
    authenticatedUser?.clientId,
    authenticatedUser?.isFamilyMode,
    consumer?.firstName,
  ]);

  function updateSelectedContract(e: any) {
    if (
      e?.detail?.clientId &&
      e?.detail?.clientReference &&
      e?.detail?.customerAccountId &&
      e?.detail?.sectionSubscriptionId
    ) {

      dispatch(authActions.setAuthentifiedUserInformations(e.detail));
      
      if (isInterventionHistorique && !e.detail.isContractTerminated) {
        dispatch(
          deliveryPointActions.fetchClientWorkOrder(
            e.detail.sectionSubscriptionId
          )
        );
      }
    }
  }

  useEffect(() => {
   
    const component = document.querySelector("contract-widget");

    if (component && !eventListenerExist) {
      component.addEventListener("contract-clicked", (e) => {
        updateSelectedContract(e);
      });
      setEventListenerExist(true);
    }
    if (component)
      return component.removeEventListener("contract-clicked", () => null);

  }, [
    eventListenerExist,
    authenticatedUser,
    dispatch,
    isInterventionHistorique,
    consumerIsContractTerminated,
  ]);
};

export default useWidgetsEventListeners;
