import { States } from "saur-states-management/lib/index";
import { getCookiesSettings } from "saur-states-management/lib/selectors/settingsSelectors";


function isAnalyticClientAgree(state: States): boolean {
    const cookies = getCookiesSettings(state)
    if (cookies) {
        const analyticCookie = cookies.find((item) => {
            return item.id === 2
          })
        
          if (analyticCookie) {
            return analyticCookie.accepted
          }
    }


    return false
}

export default isAnalyticClientAgree