import React, { FC, useContext, useEffect } from "react";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import { createGlobalStyle } from "styled-components";
import useRequestScript from "../common/useRequestScript";
import { States } from "saur-states-management";
import ID_VALUES from "../automated-tests-ids/ids";
import { langTypes } from "saur-states-management/lib/i18n";
import { useSelector } from "react-redux";
import { getLanguage, isFeatureEnabled } from "saur-states-management/lib/selectors/settingsSelectors";
import BubbleHeader from "../templates/BubbleHeader";
import { useHistory } from "react-router-dom";
import { usePath } from "../common/navigation";
import { device } from "saur-design-sys-web/lib/Theme";
import { geti18nContext } from "../common/translation";

const ContractCancellationScreen : FC<{}> = () => {
  const translation = useContext(geti18nContext()); 
  const history = useHistory();
  const { getPath } = usePath();
  const screenTitle = translation?.ContractCancellation.title;
  const state = useSelector((s: States) => s);
  const contractCancellationEnabled = isFeatureEnabled(state, "Cancel-Ease")


  useRequestScript([
    "delivery-point-info-script",
  ]);
  
  const settingState = useSelector((state: States) => state.settings);
  const lang = useSelector(getLanguage) as langTypes;

  useEffect(() => {
    if(!contractCancellationEnabled){
      goToLogin();
    }
  }, []);
 
  function goToLogin() {
    history.push(`/${lang}${getPath("login")}`);
  }

  return (
    <>
      <Styles />
        <Container className="cancelation_contract_contact_screen-container" automatedTestId={ID_VALUES.contractCancellationScreen}>
              <BubbleHeader goBack={goToLogin} title={screenTitle} />
              <widget-delivery-info
                  unloggeduser={true}
                  lang={lang} 
                  theme={settingState.theme}                
              />
          
        </Container>
    </>
  );
};

const Styles = createGlobalStyle`
&.cancelation_contract_contact_screen-container{
  widget-delivery-info{
    @media ${device.mobile} {
      padding: 20px;
    }
    @media ${device.desktop} {
      padding: 0;
      min-width: 1130px;
      width: 30%;
    }
    @media ${device.tablet} {
      margin: 200px auto;
    }
  }
}
`;

export default ContractCancellationScreen;
