import React, { FC, ReactElement } from "react";
import AppFooter from "./AppFooter";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import { css } from "styled-components";

type IProps = {
  children: ReactElement;
};


// to wrap unlogged screens with footer
const ContentUnloggedWrapper: FC<IProps> = ({ children }) => {
  return (
    <>
      <Container
        id="unlogged-content-wrapper"
        className={`content-unlogged-wrapper`}
      >
        {children}
      </Container>
      <AppFooter />
    </>
  );
};

export const ContentUnloggedWrapperStyles = css`
  .content-unlogged-wrapper {
    min-height: 100vh;
  }
`;

export default ContentUnloggedWrapper;
