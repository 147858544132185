import React, { FC, useContext, useState, useEffect } from "react";
import { ThemeType } from "saur-design-sys-web/lib/BrandsConfig";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import Word from "saur-design-sys-web/lib/Atoms/Word/Word";
import Button from "saur-design-sys-web/lib/Atoms/Input/Button/Button";
import { css } from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { colors, elevation } from "saur-design-sys-web/lib/Theme";
import { onlyForDesktop } from "../common/styles";
import { motion } from "framer-motion";
import { usePath } from "../common/navigation";
import { geti18nContext } from "../common/translation";
import { getTransation, langTypes } from "saur-states-management/lib/i18n";

import { getLanguage } from "saur-states-management/lib/selectors/settingsSelectors";
import useExpireToken from "../common/useExpireToken";
import ID_VALUES from "../automated-tests-ids/ids";
import { persistor } from "../configStore";
import useLogout from "../common/useLogout";

type modalMode = "in" | "out" | undefined;

type DesktopMenuProps = {
  visible: modalMode;
  toggleMenu: (option: modalMode) => void;
};

const DesktopMenu: FC<DesktopMenuProps> = ({ visible, toggleMenu }) => {
  useExpireToken();
  const history = useHistory();
  const { getPath } = usePath();
  const translation = useContext(geti18nContext());
  const currentLang = useSelector(getLanguage) as langTypes;
  const logoutHook = useLogout()

  function goToProfil() {
    toggleMenu("out");
    history.push(`/${currentLang}${getPath("profile")}`);
  }

  function logout() {
    toggleMenu("out");
    logoutHook()
    persistor.flush();
    persistor.purge();
    persistor.pause();
    if (ssoRef) {
      let helperFrameWindow = ssoRef.contentWindow;
      helperFrameWindow.postMessage("removeUser", "*");
    }
    setTimeout(() => {
      if (sessionStorage.getItem("sso-connection")) {
        setTimeout(() => {
          let url = process.env.REACT_APP_CCL_URL;
          if (url)
            window.location.assign(url);
        }, 200)
      } else {
        history.push(`/${currentLang}${getPath("login")}`);
      }
      sessionStorage.removeItem("sso-connection");
      sessionStorage.clear();
    }, 100);
  }

  const [ssoRef, setSsoRef] = useState<any>(null)

  useEffect(() => {
    setSsoRef(document.getElementById("sso-ccl"))
  }, []);

  return visible === "in" ? (
    <Container className="DesktopMenu">
      <motion.div
        className="DesktopMenu-content"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
      >
        <Container className="AfterBox" />
        <Container className="MenuCard">
          <Button
            className="MenuButton"
            mode="basic"
            onClick={goToProfil}
            automatedTestId={ID_VALUES.accessMyProfile}
          >
            <Word
              value={getTransation((t) => t.common.goToProfile)(translation)}
              textStyle="normalMed"
              variant="span"
            />
          </Button>
          <Container className="SeparatorList" />
          <Button
            className="MenuButton"
            mode="basic"
            onClick={logout}
            automatedTestId={ID_VALUES.logout}
          >
            <Word
              value={getTransation((t) => t.userinformation.disconnect)(
                translation
              )}
              textStyle="normalBold"
              variant="span"
            />
          </Button>
        </Container>
      </motion.div>
    </Container>
  ) : null;
};

export const DesktopMenuStyles = css`
  .DesktopMenu {
    .DesktopMenu-content {
      ${onlyForDesktop}
      position: absolute;
      top: 75px;
      right: 15px;
      z-index: ${elevation.sup.top};
      .SeparatorList {
        background-color: ${(props: ThemeType) =>
    props.theme.colors.primaryAccent};
        height: 1px;
        width: 90%;
      }
      .MenuCard {
        min-width: 150px;
        align-items: center;
        border-radius: 15px;
        -webkit-box-shadow: 0 14px 14px 3px rgba(16, 26, 49, 0.1);
        box-shadow: 0 14px 14px 3px rgba(16, 26, 49, 0.1);
        padding: 15px 20px;
        background-color: ${colors.white};
      }
      .MenuButton {
        height: 41px;
        padding: 0;
        justify-content: center;
        align-items: center;
        .sds-word {
          text-align: center;
        }
      }
      .AfterBox {
        position: absolute;
        top: 0;
        right: 15%;
        background-color: ${colors.white};
        height: 16px;
        width: 16px;
        transform: rotate(45deg) translateY(-50%);
        z-index: ${elevation.base.top};
      }
    }
  }
`;

export default DesktopMenu;
