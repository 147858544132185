import React, { FC, useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import Word from "saur-design-sys-web/lib/Atoms/Word/Word";
import Button from "saur-design-sys-web/lib/Atoms/Input/Button/Button";
import CloseIcon from "saur-design-sys-web/lib/Icons/Close";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { css } from "styled-components";
import {
  colors,
  onlyForMobile,
  elevation,
} from "saur-design-sys-web/lib/Theme";
import { UserIcon } from "../components/Icons/AccountIcon";
import CustomLink from "../components/CustomLink";
import { usePath } from "../common/navigation";
import { geti18nContext } from "../common/translation";
import { getTransation, langTypes } from "saur-states-management/lib/i18n";
import useExpireToken from "../common/useExpireToken";
import {
  getLanguage,
} from "saur-states-management/lib/selectors/settingsSelectors";
import { getAuthenticatedUser } from "saur-states-management/lib/selectors/authSelectors";
import {persistor} from "../configStore";
import useLogout from "../common/useLogout";
import useDisplayExchanges from "../common/useDisplayExchanges";

type modalMode = "in" | "out" | undefined;

type MobileMenuProps = {
  visible: modalMode;
  toggleMenu: (option: modalMode) => void;
};

const MobileMenu: FC<MobileMenuProps> = ({ visible, toggleMenu }) => {
  useExpireToken();
  const history = useHistory();
  const { getPath } = usePath();
  const translation = useContext(geti18nContext());
  const currentLang = useSelector(getLanguage) as langTypes;
  const authenticatedUser = useSelector(getAuthenticatedUser);
  const logoutHook = useLogout()
  const displayExchanges = useDisplayExchanges()

  const consumerIsContractTerminated= authenticatedUser?.defaultContract?.isContractTerminated;

  function getAnimationProperties() {
    if (visible === "in") {
      return {
        menu: {
          initial: {
            left: -243,
          },
          animate: {
            left: 0,
          },
          transition: {
            duration: 0.3,
            ease: "easeIn",
          },
        },
        overlay: {
          animate: {
            opacity: 0.6,
          },
          transition: {
            duration: 0.5,
            ease: "easeIn",
          },
        },
      };
    } else if (visible === "out") {
      return {
        menu: {
          initial: {
            left: 0,
          },
          animate: {
            left: -243,
          },
          transition: {
            duration: 0.2,
            ease: "easeOut",
          },
        },
        overlay: {
          animate: {
            opacity: 0,
          },
          transition: {
            duration: 0.2,
            ease: "easeOut",
          },
        },
      };
    }
    return {
      menu: {
        animate: {},
        transition: {},
      },
      overlay: {
        animate: {},
        transition: {},
      },
    };
  }

  const { menu, overlay } = getAnimationProperties();

  function logout() {
    toggleMenu("out");
    logoutHook()
    persistor.flush();
    persistor.purge();
    persistor.pause();
    if(ssoRef){
      let helperFrameWindow = ssoRef.contentWindow;
      helperFrameWindow.postMessage("removeUser", "*");
      }
    setTimeout(() => {
      if (sessionStorage.getItem("sso-connection")) {
        setTimeout(() => {
          let url = process.env.REACT_APP_CCL_URL;
          if (url)
            window.location.assign(url);
        }, 200)
      }
      else {
        history.push(`/${currentLang}${getPath("login")}`);
      }
      sessionStorage.removeItem("sso-connection");
    }, 100);
  }
  const [ssoRef, setSsoRef] = useState<any>(null)

  useEffect(() => {
    setSsoRef(document.getElementById("sso-ccl"))
  }, []);

  function showExchanges(): boolean {
    return displayExchanges
  }

  return (
    <Container className="MobileMenu">
      <Container className="MobileMenuContent">
        <motion.div
          className="MainMobileMenuContainer"
          initial={menu.initial}
          animate={menu.animate}
          transition={menu.transition}
        >
          <Button
            className="CloseButton"
            onClick={() => {
              toggleMenu("out");
            }}
            mode="basic"
          >
            <CloseIcon />
          </Button>

          <Container className="MenuNavigation">
            {!consumerIsContractTerminated && <Container className="NavigationItem">
              <Button
                mode="basic"
                onClick={() => {
                  toggleMenu("out");
                }}
              >
                <CustomLink to={getPath("dashboard")}>
                  <Word
                    value={getTransation((t) => t.navigations.home.label)(
                      translation
                    )}
                    textStyle="normalMed"
                    variant="span"
                  />
                </CustomLink>
              </Button>
            </Container>}

            <Container className="NavigationItem">
              <Button
                mode="basic"
                onClick={() => {
                  toggleMenu("out");
                }}
              >
                <CustomLink to={getPath("myContract")}>
                  <Word
                    value={getTransation((t) => t.navigations.myContract.label)(
                      translation
                    )}
                    textStyle="normalMed"
                    variant="span"
                  />
                </CustomLink>
              </Button>
            </Container>

            <Container className="NavigationItem">
              <Button
                mode="basic"
                onClick={() => {
                  toggleMenu("out");
                }}
              >
                <CustomLink to={getPath("myBillings")}>
                  <Word
                    value={getTransation(
                      (t) => t.navigations.myPaiements.label
                    )(translation)}
                    textStyle="normalMed"
                    variant="span"
                  />
                </CustomLink>
              </Button>
            </Container>

            {!consumerIsContractTerminated && <Container className="NavigationItem">
              <Button
                mode="basic"
                onClick={() => {
                  toggleMenu("out");
                }}
              >
                <CustomLink to={getPath("myConsumption")}>
                  <Word
                    value={getTransation(
                      (t) => t.navigations.myConsumption.label
                    )(translation)}
                    textStyle="normalMed"
                    variant="span"
                  />
                </CustomLink>
              </Button>
            </Container>}

            {showExchanges() && !consumerIsContractTerminated && (
              <Container className="NavigationItem">
                <Button
                  mode="basic"
                  onClick={() => {
                    toggleMenu("out");
                  }}
                >
                  <CustomLink to={getPath("exchanges")}>
                    <Word
                      value={getTransation(
                        (t) => t.navigations.myExchanges.label
                      )(translation)}
                      textStyle="normalMed"
                      variant="span"
                    />
                  </CustomLink>
                </Button>
              </Container>
            )}

            <Container className="NavigationItem">
              <Button
                mode="basic"
                onClick={() => {
                  toggleMenu("out");
                }}
              >
                <CustomLink to={getPath("help")}>
                  <Word
                    value={getTransation(
                      (t) => t.navigations.helpAndContacts.label
                    )(translation)}
                    textStyle="normalMed"
                    variant="span"
                  />
                </CustomLink>
              </Button>
            </Container>
          </Container>

          <Button className="DisconnectButton" mode="basic" onClick={logout}>
            <UserIcon />
            <Word
              value="Me déconnecter"
              textStyle="normalBold"
              variant="span"
            />
          </Button>
        </motion.div>
        {!visible || visible === "in" ? (
          <motion.div
            className="OverlayMenu"
            onClick={() => {
              toggleMenu("out");
            }}
            animate={overlay.animate}
            transition={overlay.transition}
          />
        ) : null}
      </Container>
    </Container>
  );
};

export const MobileMenuStyles = css`
  .MobileMenu {
    .MobileMenuContent {
      ${onlyForMobile}
      .CloseButton {
        width: 41px;
        height: 41px;
        margin-bottom: 94px;
        padding: 0;
        justify-content: flex-start;
      }
      .DisconnectButton {
        svg {
          width: 18px;
          height: 18px;
          margin-right: 11px;
          path {
            fill: ${colors.black};
          }
        }
      }
      .MenuNavigation {
        flex: 1;
      }
      .NavigationItem {
        margin-bottom: 40px;
      }
      .OverlayMenu {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${colors.black};
        opacity: 0;
        z-index: ${elevation.high.under};
      }
      .MainMobileMenuContainer {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        bottom: 0;
        width: 203px;
        left: -243px;
        background-color: ${colors.white};
        border-top-right-radius: 30px;
        z-index: ${elevation.high.default};
        padding-left: 40px;
        padding-bottom: 25px;
        padding-top: 11px;
      }
    }
  }
`;

export default MobileMenu;
