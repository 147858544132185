import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAuthenticatedUser } from "saur-states-management/lib/selectors/authSelectors";
import { getClientAccountsState } from "saur-states-management/lib/selectors/clientAccountsSelector";
import { usePath } from "../common/navigation";

// Define types for subTitle and show/hide states
type TytleType = "clientRef" | "address";
type ShowHideType = "show" | "hide";

/**
 * Custom hook for managing sections visibility based on the current URL location and isFamilyMode
 *
 * @returns {Object} An object containing states for section visibility
 */
const useShowSectionsByLocationUri = () => {
  const [subTitle, setSubtitle] = useState<TytleType>("clientRef");
  const [showActionButtons, setShowActionButtons] =
    useState<ShowHideType>("hide");
  const [showContractSection, setShowContractSection] =
    useState<ShowHideType>("hide");
  const [showSectionSubscription, setShowSectionSubscription] =
    useState<ShowHideType>("show");

  const { pathname } = useLocation();
  const { getPath } = usePath();

  const clientAccountsState = useSelector(getClientAccountsState);
  const authenticatedUser = useSelector(getAuthenticatedUser);
  const isFamilyMode = authenticatedUser?.isFamilyMode ?? false;

  useEffect(() => {
    if (
      pathname.includes(getPath("dashboard")) ||
      pathname.includes(getPath("profile"))
    ) {
      setSubtitle("address");
      setShowActionButtons("hide");
      setShowContractSection("show");
      setShowSectionSubscription("show");
    } else if (pathname.includes(getPath("myContract"))) {
      setSubtitle("clientRef");
      setShowActionButtons("show");
      setShowContractSection("show");
      setShowSectionSubscription("show");
    } else {
      setSubtitle("clientRef");
      setShowActionButtons("hide");
      setShowContractSection("hide");
      setShowSectionSubscription("show");
    }

    if (pathname.includes(getPath("dashboard")) && isFamilyMode) {
      setShowSectionSubscription("hide");
      setShowActionButtons("hide");
      setShowContractSection("hide");
    }
  }, [pathname, getPath, clientAccountsState, isFamilyMode]);

  return {
    subTitle,
    showActionButtons,
    showContractSection,
    showSectionSubscription,
  };
};

export default useShowSectionsByLocationUri;
