import { takeEvery, select } from "redux-saga/effects";
import {
  settingsActions,
  State as settingState,
} from "saur-states-management/lib/states/settingsState";
import { getSettingsState } from "saur-states-management/lib/selectors/settingsSelectors";
import { Brands } from "saur-design-sys-web/lib/BrandsConfig";
import setHotFixCss from "../common/setHotFixCss";

export function setFavicon(url: string) {
  let favElement = document.querySelector("#meta-favicon");
  if (favElement) {
    // @ts-ignore
    favElement.href = url;
  }
}

export function setFaviconByTheme(theme: Brands) {
  switch (theme) {
    case "se3r": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-SE3R.ico`);
      break;
    }
    case "sepig": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/sepig-16x16.ico`);
      break;
    }
    case "accm": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-ACCM.ico`);
      break;
    }
    case "atlantic-eau": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-Atlantic.ico`);
      break;
    }
    case "cer": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-CER.ico`);
      break;
    }
    case "cgsp": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-CGSP.ico`);
      break;
    }
    case "eau-de-garonne": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-Garonne.ico`);
      break;
    }
    case "grand-lac": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-Grand-lac.ico`);
      break;
    }
    case "saint-affo": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-Saint-affo.ico`);
      break;
    }
    case "sateg": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/saur-16x16.ico`);
      break;
    }
    case "sudeau": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-Sudeau.ico`);
      break;
    }
    case "eaux-saumur-val-de-loire": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-ESVDL.ico`);
      break;
    }
    case "saur-eau-du-morbihan": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-EDM.ico`);
      break;
    }
    case "sepra": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-SEPRA.ico`);
      break;
    }
    case "sbaa": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-Sbaa.ico`);
      break;
    }
    case "gmva": {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/Favicon-GMVA.ico`);
      break;
    }
    default: {
      setFavicon(`${process.env.PUBLIC_URL}/favicons/saur-16x16.ico`);
      break;
    }
  }
}

function* createFaviconEffect() {
  const settingsState: settingState = yield select(getSettingsState);
  if (settingsState && settingsState.params.BrandReference) {
    setFaviconByTheme(settingsState.params.BrandReference);
    setHotFixCss(settingsState.params.BrandReference);
  }
}

function* settingsEffetcs() {
  yield takeEvery(
    settingsActions.getBrandDataSuccess.type,
    createFaviconEffect
  );
}

export default settingsEffetcs;
