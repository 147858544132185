import { useContext } from "react";
import { getTransation } from "saur-states-management/lib/i18n";
import { geti18nContext } from "./translation";

const _routes = {
  dashboard: "dashboard",
  login: "login",
  createAccount: "createAccount",
  myConsumption: "my-consumption",
  myContract: "my-contract",
  myBillings: "invoices",
  exchanges: "exchanges",
  help: "help",
  profile: "profile",
  forgetPassword: "forgetPassword",
  paymentReturn: "paymentReturn",
  createAccountThirdStep: "createAccountThirdStep",
  updateEmailValidation: "updateEmailValidation",
  UpdateEmailOffline: "UpdateEmailOffline",
  ccl: "ccl",
  moveIn: "moveIn",
  contactEmailValidation: "contactEmailValidation",
  deliveryPointRequestsTracking: "deliveryPointRequestsTracking",
  contractCancelation: "contractCancelation",
};

const routes = () => _routes;

export function usePath() {
  const translation = useContext(geti18nContext());

  function getPath(value: keyof typeof _routes): string {
    switch (value) {
      case "dashboard":
        return `/${getTransation((t) => t.navigations.home.uri)(translation)}`;
      case "login":
        return `/${getTransation((t) => t.navigations.login.uri)(translation)}`;
      case "createAccount":
        return `/${getTransation(
          (t) => t.navigations.createAccountFirstSteps.uri
        )(translation)}`;
      case "moveIn":
        return `/${getTransation(
          (t) => t.navigations.moveIn.uri
        )(translation)}`;
      case "createAccountThirdStep":
        return `/${getTransation(
          (t) => t.navigations.createAccountThirdStep.uri
        )(translation)}`;
      case "myConsumption":
        return `/${getTransation((t) => t.navigations.myConsumption.uri)(
          translation
        )}`;
      case "myContract":
        return `/${getTransation((t) => t.navigations.myContract.uri)(
          translation
        )}`;
      case "myBillings":
        return `/${getTransation((t) => t.navigations.myPaiements.uri)(
          translation
        )}`;
      case "exchanges":
        return `/${getTransation((t) => t.navigations.myExchanges.uri)(
          translation
        )}`;
      case "help":
        return `/${getTransation((t) => t.navigations.helpAndContacts.uri)(
          translation
        )}`;
      case "profile":
        return `/${getTransation((t) => t.navigations.myProfile.uri)(
          translation
        )}`;
      case "forgetPassword":
        return `/${getTransation((t) => t.navigations.forgetPassword.uri)(
          translation
        )}`;
      case "UpdateEmailOffline":
        return `/${getTransation((t) => t.navigations.updateEmailByBillingOrIban.uri)(
          translation
        )}`;
      case "updateEmailValidation":
        return `/${getTransation(
          (t) => t.navigations.updateEmailValidation.uri
        )(translation)}`;
      case "paymentReturn":
        return `/${getTransation((t) => t.navigations.paymentReturn.uri)(
          translation
        )}`;
      case "ccl":
        return `/${getTransation((t) => t.navigations.cclAccess.uri)(
          translation
        )}`;
      case "contactEmailValidation":
        return `/${getTransation((t) => t.navigations.contactEmailValidation.uri)(
          translation
        )}`;
      case "deliveryPointRequestsTracking":
        return `/${getTransation((t) => t.navigations.deliveryPointRequestsTracking.uri)(
          translation
        )}`;
      case "contractCancelation":
        return `/${getTransation((t) => t.navigations.contractCancelation.uri)(
          translation
        )}`;
      default:
        return `/`;
    }
  }
  return { getPath };
}

export default routes;
