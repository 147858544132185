/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  FC,
  ReactElement,
  useContext,
  lazy,
  Suspense,
  useRef
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { settingsActions } from "saur-states-management/lib/states/settingsState";
import { getAccessToken, getAuthenticatedUser } from "saur-states-management/lib/selectors/authSelectors";
import {
  getLanguage,
  isFeatureEnabled,
  getCookiesSettings
} from "saur-states-management/lib/selectors/settingsSelectors";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { langTypes, getTransation } from "saur-states-management/lib/i18n";
import { Switch, Route, Redirect, useParams, useHistory, useLocation } from "react-router-dom";
import { usePath } from "../common/navigation";
import ContentLoggedWrapper from "../templates/ContentLoggedWrapper";
import ContentUnloggedWrapper from "../templates/ContentUnloggedWrapper";
import { States } from "saur-states-management";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import Loader from "saur-design-sys-web/lib/Atoms/Loader/Loader";

import { css } from "styled-components";
import CookiesBanner from "../components/CookiesBanner";

import { geti18nContext } from "../common/translation";
import CCLLoadingScreen from "../screens/CCLLoadingScreen";
import SubscriptionScreen from "../screens/SubscriptionScreen";
import AlertContactScreen from "../screens/AlertContactScreen";
import DeliveryPointRequestTrackingScreen from "../screens/DeliveryRequestTrackingScreen";
import ContractCancellationScreen from "../screens/ContractCancellationScreen";


const LoginScreen = lazy(() => import("../screens/LoginScreen"));
const DashboardScreen = lazy(() => import("../screens/DashboardScreen"));
const MyConsomptionScreen = lazy(() =>
  import("../screens/MyConsomptionScreen")
);
const HelpContactsScreen = lazy(() => import("../screens/HelpContactsScreen"));
const MyContractScreen = lazy(() => import("../screens/MyContractScreen"));
const MyExchangesScreen = lazy(() => import("../screens/MyExchangesScreen"));
const MyPaiementsScreen = lazy(() => import("../screens/MyPaiementsScreen"));
const ForgetPasswordScreen = lazy(() =>
  import("../screens/ForgetPasswordScreen")
);
const PaymentResultScreen = lazy(() =>
  import("../screens/PaymentResultScreen")
);
const CreateAccountScreen = lazy(() =>
  import("../screens/CreateAccountScreen")
);
const UpdateEmailValidationScreen = lazy(() =>
  import("../screens/UpdateEmailValidationScreen")
);

const UpdateEmailWithBillingOrIbanScreen = lazy(() =>
  import("../screens/UpdateEmailWithBillingOrIbanScreen")
);
const MyProfil = lazy(() => import("../screens/MyProfil"));

export const PageNamesForMatomo = {
  login: 'Authent',
  forgetPassword: 'Mdp oublié',
  createAccount: 'Création compte',
  home: 'Homepage',
  contract: 'Contrat',
  consumption: 'Conso',
  help: 'Aide-contact',
  exchanges: 'Echanges',
  paiements: 'Paiements-facture',
  profil: 'Profile',
  paiementResult: 'Paiement-resultat',
  createAccountThirdStep: 'Création compte - confirmation',
  updateEmailValidation: 'Mise à jour email - validation',
  paymentReturn: 'Retour de paiement',
  updateEmailOffline: 'Changer mon adresse email avec mon iban ou ma facture',
  subscription: "page d'abonnement",
  contactEmailValidation: "Validation email contact",
  deliveryPointRequestsTracking: "Suivi des demandes de branchement",
  contractCancelation: "Résiliation de contract",
}

export const CheckLang: FC<{ children: ReactElement }> = ({ children }) => {
  const params = useParams<{ lang: string }>();
  const dispatch = useDispatch();
  const currentLang = useSelector(getLanguage) as langTypes;
  const settingState = useSelector((state: States) => state.settings);
  const translation = useContext(geti18nContext());
  const clientArea = getTransation((t) => t.common.clientArea)(translation);

  if (settingState.params) {
    document.title = `${settingState.params.BrandFullName} - ${clientArea}`;
  }

  useEffect(() => {
    if (params.lang && params.lang !== currentLang) {
      dispatch(settingsActions.setLanguage(params.lang));
    }
  }, [params]);
  return children;
};

const LoaderScreen = () => (
  <Container className="LoaderScreen">
    <Loader className="LoaderScreenLoader" />
  </Container>
);

export const LoaderScreenStyles = css`
  .LoaderScreen {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    .LoaderScreenLoader {
      top: 50%;
      left: 50%;
      position: fixed;
      transform: translateX(-50%) translateY(-50%);
    }
  }
`;

const Navigation = () => {
  const { getPath } = usePath();
  const currentLang = useSelector(getLanguage) as langTypes;
  const state = useSelector((_state: States) => _state);
  const translation = useContext(geti18nContext());

  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }


  const history = useLocation();
  const nav = useHistory();
  const cookiesSettings = useSelector(getCookiesSettings);
  const { trackPageView } = useMatomo();
  const prevHistoryPath = usePrevious(history.pathname);


  useEffect(() => {
    if (history.hash.length) {
      let route = history.hash.replace('#', '');
      nav.push(route);
    }
  }, [history])

  function getMatomoName(uriPathName: string) {
    switch (history.pathname) {
      case `/${currentLang}/${getTransation((t) => t.navigations.login.uri)(translation)}`:
        return PageNamesForMatomo.login
      case `/${currentLang}/${getTransation((t) => t.navigations.home.uri)(translation)}`:
        return PageNamesForMatomo.home
      case `/${currentLang}/${getTransation((t) => t.navigations.createAccountFirstSteps.uri)(translation)}`:
        return PageNamesForMatomo.createAccount
      case `/${currentLang}/${getTransation((t) => t.navigations.forgetPassword.uri)(translation)}`:
        return PageNamesForMatomo.forgetPassword
      case `/${currentLang}/${getTransation((t) => t.navigations.myContract.uri)(translation)}`:
        return PageNamesForMatomo.contract
      case `/${currentLang}/${getTransation((t) => t.navigations.myPaiements.uri)(translation)}`:
        return PageNamesForMatomo.paiementResult
      case `/${currentLang}/${getTransation((t) => t.navigations.myConsumption.uri)(translation)}`:
        return PageNamesForMatomo.consumption
      case `/${currentLang}/${getTransation((t) => t.navigations.helpAndContacts.uri)(translation)}`:
        return PageNamesForMatomo.help
      case `/${currentLang}/${getTransation((t) => t.navigations.myProfile.uri)(translation)}`:
        return PageNamesForMatomo.profil
      case `/${currentLang}/${getTransation((t) => t.navigations.myExchanges.uri)(translation)}`:
        return PageNamesForMatomo.exchanges
      case `/${currentLang}/${getTransation((t) => t.navigations.createAccountThirdStep.uri)(translation)}`:
        return PageNamesForMatomo.createAccountThirdStep
      case `/${currentLang}/${getTransation((t) => t.navigations.updateEmailValidation.uri)(translation)}`:
        return PageNamesForMatomo.updateEmailValidation
      case `/${currentLang}/${getTransation((t) => t.navigations.paymentReturn.uri)(translation)}`:
        return PageNamesForMatomo.paymentReturn
      case `/${currentLang}/${getTransation((t) => t.navigations.updateEmailByBillingOrIban.uri)(translation)}`:
        return PageNamesForMatomo.updateEmailOffline
      case `/${currentLang}/${getTransation((t) => t.navigations.moveIn.uri)(translation)}`:
        return PageNamesForMatomo.subscription
      case `/${currentLang}/${getTransation((t) => t.navigations.contactEmailValidation.uri)(translation)}`:
        return PageNamesForMatomo.contactEmailValidation
      case `/${currentLang}/${getTransation((t) => t.navigations.deliveryPointRequestsTracking.uri)(translation)}`:
          return PageNamesForMatomo.contactEmailValidation
      case `/${currentLang}/${getTransation((t) => t.navigations.contractCancelation.uri)(translation)}`:
        return PageNamesForMatomo.contractCancelation
    }
  }

  useEffect(() => {
    const isAnalyticsAgree = (): boolean => {
      if (cookiesSettings) {
        const cookie = cookiesSettings.find((cs) => cs.id === 2)
        if (cookie) {
          return cookie.accepted
        }
      }
      return true
    }
    if (isAnalyticsAgree() && process.env.NODE_ENV === 'production') {
      if (history.pathname !== prevHistoryPath) {
        let cclAccess = "";
        if (sessionStorage.getItem("sso-connection"))
          cclAccess = " [Accès CCL]"
        trackPageView({ documentTitle: getMatomoName(history.pathname) + cclAccess })
      }
    }
  }, [cookiesSettings, trackPageView, history.pathname])

  const authenticatedUser = useSelector(getAuthenticatedUser);

  const consumerIsContractTerminated = authenticatedUser?.defaultContract?.isContractTerminated;
  const isFamilyMode = authenticatedUser?.isFamilyMode || false

  function renderRouteContent(
    element: React.ReactElement,
    isLogged: boolean,
    isDashboard: boolean = false
  ) {
    return (
      <CheckLang>
        <>
          {isLogged ? (
            <ContentLoggedWrapper isDashboard={isDashboard}>
              {element}
            </ContentLoggedWrapper>
          ) : (
            <ContentUnloggedWrapper>
              {element}
            </ContentUnloggedWrapper>
          )}
          <CookiesBanner mode="banner" />
        </>
      </CheckLang>
    );
  }


  function showUpdateEmail(): boolean {
    return isFeatureEnabled(state, "Client-Identifiant-Bloque")
  }

  return (
    <Suspense fallback={<LoaderScreen />}>
      <Switch>
        {(!consumerIsContractTerminated || isFamilyMode) && (
          <PrivateRoute exact path={`/:lang${getPath("dashboard")}`}>
            {renderRouteContent(<DashboardScreen />, true, true)}
          </PrivateRoute>
        )}
        {(!consumerIsContractTerminated || isFamilyMode) && (
          <PrivateRoute exact path={`/:lang${getPath("myConsumption")}`}>
            {renderRouteContent(<MyConsomptionScreen />, true)}
          </PrivateRoute>
        )}
        <PrivateRoute exact path={`/:lang${getPath("help")}`}>
          {renderRouteContent(<HelpContactsScreen />, true)}
        </PrivateRoute>

        {!isFamilyMode && <PrivateRoute exact path={`/:lang${getPath("myContract")}`}>
          {renderRouteContent(<MyContractScreen />, true)}
        </PrivateRoute>}
        {!isFamilyMode && <PrivateRoute exact path={`/:lang${getPath("exchanges")}`}>
          {renderRouteContent(<MyExchangesScreen />, true)}
        </PrivateRoute>}
        {!isFamilyMode && <PrivateRoute exact path={`/:lang${getPath("myBillings")}`}>
          {renderRouteContent(<MyPaiementsScreen />, true)}
        </PrivateRoute>}
        <PrivateRoute exact path={`/:lang${getPath("profile")}`}>
          {renderRouteContent(<MyProfil />, true)}
        </PrivateRoute>
        {!isFamilyMode && <PrivateRoute exact path={`/:lang${getPath("paymentReturn")}`}>
          {renderRouteContent(<PaymentResultScreen />, false)}
        </PrivateRoute>}
        <Route exact path={`/:lang${getPath("forgetPassword")}`}>
          {renderRouteContent(<ForgetPasswordScreen />, false)}
        </Route>
        <Route path={`/:lang${getPath("updateEmailValidation")}`}>
          {renderRouteContent(<UpdateEmailValidationScreen />, false)}
        </Route>

        {showUpdateEmail() && (
          <Route path={`/:lang${getPath("UpdateEmailOffline")}`}>
            {renderRouteContent(<UpdateEmailWithBillingOrIbanScreen />, false)}
          </Route>
        )}

        <Route exact path={`/:lang${getPath("createAccount")}`}>
          {renderRouteContent(
            <CreateAccountScreen step="create-user-infos" />,
            false
          )}
        </Route>
        <Route path={`/:lang${getPath("createAccountThirdStep")}`}>
          {renderRouteContent(
            <CreateAccountScreen step="create-password" />,
            false
          )}
        </Route>
        <Route path={`/:lang${getPath("moveIn")}`}>
          {renderRouteContent(
            <SubscriptionScreen />,
            false
          )}
        </Route>
        <Route exact path={`/:lang${getPath("login")}`}>
          {renderRouteContent(<LoginScreen />, false)}
        </Route>
        <Route path={`/${getPath("createAccountThirdStep")}`}>
          <Redirect to={`/${currentLang}${getPath("createAccountThirdStep")}`} />
        </Route>
        <Route exact path={`/:lang${getPath("ccl")}`}>
          {renderRouteContent(<CCLLoadingScreen />, false)}
        </Route>
        <Route path={`/:lang${getPath("contactEmailValidation")}`}>
          {renderRouteContent(<AlertContactScreen />, false)}
        </Route>
        <Route path={`/:lang${getPath("deliveryPointRequestsTracking")}`}>
          {renderRouteContent(<DeliveryPointRequestTrackingScreen />, false)}
        </Route>
        <Route path={`/:lang${getPath("contractCancelation")}`}>
          {renderRouteContent(
            <ContractCancellationScreen />,
            false
          )}
        </Route>
        <Route path="*">
          {
            !consumerIsContractTerminated || isFamilyMode ?
              < Redirect to={`/${currentLang}${getPath("dashboard")}`} /> :
              < Redirect to={`/${currentLang}${getPath("myBillings")}`} />
          }
        </Route>
      </Switch>
    </Suspense>
  );
};


const PrivateRoute: FC<{
  children: ReactElement;
  path: string;
  exact?: boolean;
}> = ({ children, path, exact = false }) => {
  const accessToken = useSelector(getAccessToken);
  const currentLang = useSelector(getLanguage) as langTypes;

  const { getPath } = usePath();
  const supportedLanguages = ["fr", "en"];

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        function checkLang() {
          if (props.match.params.lang && supportedLanguages.includes(props.match.params.lang)) {
            return <Redirect to={`/${currentLang}${getPath("login")}`} />;
          } else {
            return <Redirect to={`/fr${getPath("login")}`} />;
          }
        }
        if (!!accessToken) {
          return children;
        } else {
          return checkLang();
        }
      }}
    />
  );
};

export default Navigation;
