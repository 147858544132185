import React, { FC, useContext } from "react";
import { useSelector } from "react-redux";
import { getConsumer } from "saur-states-management/lib/selectors/consumerSelector";
import { useHistory } from "react-router-dom";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import Word from "saur-design-sys-web/lib/Atoms/Word/Word";
import Button from "saur-design-sys-web/lib/Atoms/Input/Button/Button";
import AppLogo from "saur-design-sys-web/lib/Atoms/AppLogo/AppLogo";
import Consumer from "saur-states-management/lib/models/consumer";
import { css } from "styled-components";
import { device, elevation } from "saur-design-sys-web/lib/Theme";
import { ThemeType } from "saur-design-sys-web/lib/BrandsConfig";
import AccountIcon from "../components/Icons/AccountIcon";
import {
  onlyForDesktop,
  onlyForMobile,
  onlyForLargeScreen,
} from "../common/styles";
import BurgerMenuIcon from "../components/Icons/BurgerMenuIcon";
import DesktopMenu from "../components/DesktopMenu";
import CustomLink from "../components/CustomLink";
import { usePath } from "../common/navigation";
import { getTransation, langTypes } from "saur-states-management/lib/i18n";
import { geti18nContext } from "../common/translation";
import {
  getLanguage,
  isFeatureEnabled,
} from "saur-states-management/lib/selectors/settingsSelectors";
import ID_VALUES from "../automated-tests-ids/ids";
import { States } from "saur-states-management";
import { getAuthenticatedUser } from "saur-states-management/lib/selectors/authSelectors";

type modalMode = "in" | "out" | undefined;

type AppHeaderProps = {
  toggleMenu: (option: modalMode) => void;
  menuStatus: modalMode;
  showClientProMenu?: boolean;
};

export const AppHeader: FC<AppHeaderProps> = ({ toggleMenu, menuStatus, showClientProMenu = false }) => {
  const translation = useContext(geti18nContext());
  //locals
  const home = getTransation((t) => t.navigations.home.label)(translation);
  const myContract = getTransation((t) => t.navigations.myContract.label)(
    translation
  );
  const myPaiements = getTransation((t) => t.navigations.myPaiements.label)(
    translation
  );
  const consumer = useSelector(getConsumer) as Consumer;

  const authenticatedUser = useSelector(getAuthenticatedUser);
  const consumerIsContractTerminated = authenticatedUser?.defaultContract?.isContractTerminated;
  const history = useHistory();
  const state = useSelector((s: States) => s);
  const { getPath } = usePath();
  const currentLang = useSelector(getLanguage) as langTypes;
  const showExchanges = isFeatureEnabled(state, "AlertesSurconso") || isFeatureEnabled(state, "Intervention-Afficher-Historique") || isFeatureEnabled(state, "Suivi-Demandes")

  function goToProfile() {
    history.push(`/${currentLang}${getPath("profile")}`);
  }
  const myConsumption = getTransation((t) => t.navigations.myConsumption.label)(
    translation
  );
  const myExchanges = getTransation((t) => t.navigations.myExchanges.label)(
    translation
  );
  const helpAndContacts = getTransation(
    (t) => t.navigations.helpAndContacts.label
  )(translation);
  const welcome = getTransation((t) => t.common.welcome)(translation);


  return (
    <Container id="header-app" className={`AppHeader`}>
      <Container className="HeaderMainContainer">
        <Container className="BurgerMenuContainer">
          <Button
            mode="basic"
            onClick={() => {
              toggleMenu(menuStatus === "in" ? "out" : "in");
            }}
          >
            <BurgerMenuIcon />
          </Button>
        </Container>
        <Container className="HeaderLeft">
          <AppLogo type="lighten" content="header" />
          <Container className="Separator" />
          <Word
            className="HeaderTitle"
            value={getTransation((t) => t.common.clientArea)(translation)}
            textStyle="normalBold"
            variant="span"
          />
          {sessionStorage.getItem("sso-connection") &&
            <Word
              className="ccl"
              value="Accès CCL"
              textStyle="normalBold"
              variant="span"
            />
          }
        </Container>

        <Container className="HeaderNavigation">
          {(!consumerIsContractTerminated || showClientProMenu) && <CustomLink
            to={getPath("dashboard")}
            automatedTestId={ID_VALUES.dashboard}
          >
            <Word
              className="NavLabel"
              value={home}
              textStyle="normalMed"
              variant="span"
            />
          </CustomLink>}
          {!showClientProMenu && <CustomLink
            to={getPath("myContract")}
            automatedTestId={ID_VALUES.contracts}
          >
            <Word
              className="NavLabel"
              value={myContract}
              textStyle="normalMed"
              variant="span"
            />
          </CustomLink>}
          {!showClientProMenu && <CustomLink
            to={getPath("myBillings")}
            automatedTestId={ID_VALUES.billings}
          >
            <Word
              className="NavLabel"
              value={myPaiements}
              textStyle="normalMed"
              variant="span"
            />
          </CustomLink>}
          {(!consumerIsContractTerminated || showClientProMenu) && <CustomLink
            to={getPath("myConsumption")}
            automatedTestId={ID_VALUES.consumption}
          >
            <Word
              className="NavLabel"
              value={myConsumption}
              textStyle="normalMed"
              variant="span"
            />
          </CustomLink>}
          {!showClientProMenu && showExchanges && !consumerIsContractTerminated && (
            <CustomLink
              to={getPath("exchanges")}
              automatedTestId={ID_VALUES.exchange}
            >
              <Word
                className="NavLabel"
                value={myExchanges}
                textStyle="normalMed"
                variant="span"
              />
            </CustomLink>
          )}
          {!showClientProMenu && <CustomLink to={getPath("help")} automatedTestId={ID_VALUES.help}>
            <Word
              className="NavLabel"
              value={helpAndContacts}
              textStyle="normalMed"
              variant="span"
            />
          </CustomLink>}
        </Container>
        <Container className="HeaderRight">
          <Button
            className="MenuMobileButton"
            mode="basic"
            onClick={goToProfile}
          >
            <AccountIcon />
          </Button>
          <Button
            className="MenuDesktopButton"
            mode="basic"
            onClick={() => {
              toggleMenu("in");
            }}
            automatedTestId={ID_VALUES.menuDesktopButton}
          >
            <Container className="MenuDesktopButtonContent">
              <Container
                className="WelcomeContainer"
                automatedTestId={ID_VALUES.welcomeUser}
              >
                <Word
                  className="WelcomeLabel"
                  value={welcome}
                  textStyle="normalMed"
                  variant="span"
                />
                <Word
                  className="WelcomeLabel"
                  automatedTestId={ID_VALUES.userName}
                  value={`${consumer?.firstName ?? ""} ${consumer?.lastName ?? ""}`}
                  textStyle="normalMed"
                  variant="span"
                />
              </Container>
              <AccountIcon />
            </Container>
          </Button>
        </Container>
      </Container>
      <DesktopMenu visible={menuStatus} toggleMenu={toggleMenu} />
    </Container>
  );
};

export const AppHeaderStyles = css`
  .AppHeader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.primaryLight};
    z-index: ${elevation.base.top};
    background-color: transparent;
    .WelcomeContainer {
      ${onlyForDesktop}
      margin-right: 15px;
      align-self: center;
      .WelcomeLabel {
        font-size: 12px;
        line-height: 18px;
      }
    }
    .NavLabel {
      color: ${(props: ThemeType) => props.theme.texts.lightColor};
    }
    .MenuMobileButton {
      ${onlyForMobile}
      position: absolute;
      right: 25px;
      top: 60%;
      transform: translateY(-50%);
    }
    .WelcomeLabel {
      color: ${(props: ThemeType) => props.theme.texts.lightColor};
      text-align: right;
    }
    .MenuDesktopButton {
      ${onlyForDesktop}
      margin-top: 5px;
      .MenuDesktopButtonContent {
        flex-direction: row;
      }
    }
    .HeaderRight {
      flex-direction: row;
    }
    .HeaderNavigation {
      ${onlyForDesktop}
      flex-direction: row;
      align-items: center;
      .sds-m-nav {
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .BurgerMenuContainer {
      ${onlyForMobile};
    }
    .HeaderMainContainer {
      flex-direction: row;
      background-color: ${(props: ThemeType) => props.theme.colors.primaryDark};
      border-bottom-right-radius: 60px;
      padding: 0 30px;
      align-items: center;
      justify-content: space-between;
      @media ${device.mobile} {
        height: 51px;
        padding: 0 25px;
      }
      @media ${device.tablet} {
        padding: 13px 25px 13px 25px;
      }
    }
    .HeaderLeft {
      @media ${device.mobile} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      @media ${device.tablet} {
        position: relative;
        top: inherit;
        left: inherit;
        transform: inherit;
        width: auto;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
      .sds-app-logo {
        width: auto;
      }
    }
    .ccl{
      padding: 5px 15px;
      margin-left:10px;
      background-color: #FFBF66;
      border-radius:30px;
    }
    .Separator {
      ${onlyForLargeScreen}
      width: 3px;
      border-radius: 9px;
      height: 25px;
      background-color: ${(props: ThemeType) => props.theme.texts.lightColor};
      margin: 0 20px;
    }
    .HeaderTitle {
      white-space: nowrap;
      @media ${device.mobile} {
        display: none;
      }
      @media ${device.desktop} {
        display: flex;
        font-size: 13px;
        font-family: ${(props: ThemeType) => props.theme.texts.fontFamilyBold};
      }
      color: ${(props: ThemeType) => props.theme.texts.lightColor};
      width: fit-content;
    }
  }
`;

export default AppHeader;
