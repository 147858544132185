import { useSelector } from "react-redux";
import { getAuthenticatedUser } from "saur-states-management/lib/selectors/authSelectors";
import { isFeatureEnabled } from "saur-states-management/lib/selectors/settingsSelectors";
import { States } from "saur-states-management";
import {
  getOverConsumptionAlerts,
  getClientWorkOrderState,
} from "saur-states-management/lib/selectors/deliveryPointSelector";

const useDisplayExchanges = () => {
  const authenticatedUser = useSelector(getAuthenticatedUser);
  const clientWorkOrderState = useSelector(getClientWorkOrderState);
  const overConsumptionList = useSelector(getOverConsumptionAlerts);
  const state = useSelector((s: States) => s);
  const overConsumptionListFiltred = overConsumptionList.filter(
    (oc) => oc?.notificationsList.length !== 0
  );
  if (
    isFeatureEnabled(state, "AlertesSurconso") &&
    overConsumptionListFiltred.length !== 0
  ) {
    return true;
  }
  if (
    isFeatureEnabled(state, "Intervention-Afficher-Historique") &&
    clientWorkOrderState.data.length > 0
  ) {
    return true;
  }
  if (
    isFeatureEnabled(state, "Alertes-Surconsommations-Fuites-Residuelles") &&
    authenticatedUser?.pairingTechnologyCode === "TeleCoronis"
  ) {
    return true;
  }
  return false;
};

export default useDisplayExchanges
