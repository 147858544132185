import React, { FC } from 'react';
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import {css} from 'styled-components'


const AccountIcon: FC<{}> = () => {
    return (
        <Container className="AccountIconContainer">
            <Circle />
            <Container className="StyledUser">
                <UserIcon />
            </Container>
        </Container>
    )
}

export const AccountIconStyles = css`
    .AccountIconContainer {
        position: relative;
        width: 31px;
        height: 40px;
        .StyledCircle {
            position: absolute;
            width: 31px;
            height: 32px;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            svg {
                width: 100%;
                height: 100%;
            }
        }
        .StyledUser {
            position: absolute;
            width: 26px;
            height: 26px;
            top: 25%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    .headerUserIcon{
        fill: #008CD2;
    }
`

const Circle = () => (
    <svg className="StyledCircle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
        <defs/>
        <path className="headerUserIcon" fillRule="evenodd" d="M9.695 2.575C3.643 5.508-1.025 12.262.379 19.355c1.377 6.956 8.631 12.162 15.3 11.663 6.67-.499 12.148-6.024 13.663-12.735.807-3.58.589-7.547-1.017-11.008-.787-1.696-2.212-4.021-3.737-5.089-.996-.697-2.064-1.296-3.2-1.637-1.806-.543-3.697-.136-5.47.125-2.167.319-4.271.955-6.223 1.9"/>
    </svg>
)


export const UserIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24">
        <defs/>
        <path fill="#FFF" fillRule="evenodd" d="M1.392 23.165c-.105 0-.174 0-.279-.035-.452-.139-.66-.626-.521-1.078 1.774-5.009 6.539-8.348 11.86-8.348 5.218 0 9.983 3.305 11.792 8.209.174.452-.07.94-.487 1.078-.452.174-.94-.07-1.078-.487-1.566-4.243-5.67-7.095-10.227-7.095-4.59 0-8.73 2.921-10.26 7.234-.14.314-.453.522-.8.522zm11.06-10.852c-3.408 0-6.156-2.783-6.156-6.156C6.296 2.783 9.079 0 12.452 0c3.374 0 6.157 2.783 6.157 6.157 0 3.373-2.748 6.156-6.157 6.156zm0-10.643c-2.47 0-4.486 2.017-4.486 4.487s2.017 4.486 4.486 4.486c2.47 0 4.487-2.017 4.487-4.486 0-2.47-2.017-4.487-4.487-4.487z"/>
    </svg>
)


export default AccountIcon;