import React from 'react';

const BurgerMenuIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="21" viewBox="0 0 28 21">
        <g fill="#FFF">
            <path d="M26.833 11.351H1.167C.523 11.351 0 10.843 0 10.216c0-.626.523-1.135 1.167-1.135h25.666c.644 0 1.167.509 1.167 1.135 0 .627-.523 1.135-1.167 1.135zM26.833 2.27H1.167C.523 2.27 0 1.762 0 1.135 0 .51.523 0 1.167 0h25.666C27.477 0 28 .509 28 1.135c0 .627-.523 1.135-1.167 1.135zM13.412 20.432H1.167C.523 20.432 0 19.924 0 19.297c0-.626.523-1.135 1.167-1.135h12.245c.644 0 1.166.509 1.166 1.135 0 .627-.522 1.135-1.166 1.135z"/>
        </g>
    </svg>
)

export default BurgerMenuIcon