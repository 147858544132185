import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { usePath } from "../common/navigation";
import { States } from "saur-states-management";
import { getLanguage } from "saur-states-management/lib/selectors/settingsSelectors";
import { langTypes } from "saur-states-management/lib/i18n";

function useExpireToken() {
  const { getPath } = usePath();
  const history = useHistory();
  const expireDateToken = useSelector(
    (state: States) => state.auth.data.expireDate
  );
  const currentLang = useSelector(getLanguage) as langTypes;
  useEffect(() => {
    if (
      expireDateToken &&
      new Date(expireDateToken).getTime() < new Date().getTime()
    ) {
      history.push(`/${currentLang}${getPath("login")}`);
    }
  });
}

export default useExpireToken;
