import { createContext, Context } from 'react'
import { langProperty } from "saur-states-management/lib/i18n";

const i18nContext = createContext<langProperty | null>(null)

function geti18nContext(): Context<langProperty> {
    const value = i18nContext as Context<langProperty>
    return value 
}



export {
    i18nContext,
    geti18nContext,
}