import React, { FC, useContext } from "react";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import Word from "saur-design-sys-web/lib/Atoms/Word/Word";
import Button from "saur-design-sys-web/lib/Atoms/Input/Button/Button";
import AppLogo from "saur-design-sys-web/lib/Atoms/AppLogo/AppLogo";
import LeftArrow from "saur-design-sys-web/lib/Icons/LeftArrow";
import { ThemeType } from "saur-design-sys-web/lib/BrandsConfig";
import { colors, onlyForDesktop, device, onlyForMobile, elevation } from "saur-design-sys-web/lib/Theme";
import { css } from "styled-components";
import { geti18nContext } from "../common/translation";
import { getTransation } from "saur-states-management/lib/i18n";


type BubbleHeaderProps = {
  goBack: () => void;
  wide?: boolean;
  title?: string
};

const BubbleHeader: FC<BubbleHeaderProps> = ({ goBack, wide = false, title }) => {
  const translation = useContext(geti18nContext());
  return (
    <Container className="BubbleHeader">
      <Container className="BubbleHeader-header">
        <Container className="BubbleHeader-header-row">
          <Container className="BubbleHeader-BackButtonMobile">
            <Button mode="basic" onClick={goBack}>
              <LeftArrow />
            </Button>
          </Container>
          <AppLogo type="lighten" content="register"/>
          <Container className="BubbleHeader-header-separator" />
          <Word
            className="BubbleHeader-header-title-desktop"
            value={title ?? getTransation((t) => t.common.clientArea)(translation)}
            textStyle="title"
            variant="p"
          />
        </Container>
      </Container>
      <Container className={wide ? " BubbleHeader-header-circle  wide-bubble" : "BubbleHeader-header-circle"} />
    </Container>
  );
};

export const BubbleHeaderStyles = css`
  .BubbleHeader {
    z-index:0;
      position: relative;
      background-color: ${(props: ThemeType) =>
    props.theme.colors.primaryLight};
    .BubbleHeader-header {  
        z-index: ${elevation.sup.default};
        align-items: center;
        justify-content: center;
        @media ${device.mobile} {
            position: relative;
            width: 100%;
            background-color: ${(props: ThemeType) => props.theme.colors.primaryDark};
            transform: translateX(0%);
            height: 51px;
            top: 0;
            left: 0;
            border-bottom-left-radius: 30px;
        }
        @media ${device.tablet} {
            position: absolute;
            transform: translateX(-50%);
            height: 400px;
            background-color: transparent;
            top: 0;
            left: 50%;
            border-bottom-left-radius: 0;
            padding: 0;

        }
      .BubbleHeader-header-row {
        align-items: center;
        @media ${device.mobile} {
            width: 100%;
            flex-direction: column;

        }
        @media ${device.tablet} {
            transform: translateY(-125%);
            flex-direction: row;
            width: auto;
        }
        .BubbleHeader-BackButtonMobile {
            ${onlyForMobile}
            position: absolute;
            left: 4%;
            top: 50%;
            transform: translateY(-50%);
            svg {
                width: 20px;
                height: 20px;
                path {
                    fill: ${colors.white}
                }
            }
        }
      }
      .BubbleHeader-header-separator {
        ${onlyForDesktop}
        width: 5px;
        height: 42px;
        background-color: ${(props: ThemeType) => props.theme.texts.lightColor};
        border-radius: 9px;
        margin: 0 20px;
      }
      .BubbleHeader-header-title-desktop {
        ${onlyForDesktop}
        color: ${(props: ThemeType) => props.theme.texts.lightColor};
        white-space: nowrap;
      }
      .BubbleHeader-header-title-mobile {
        ${onlyForMobile}
        color: ${(props: ThemeType) => props.theme.texts.lightColor};
      }
    }
    .BubbleHeader-header-circle {
        ${onlyForDesktop}
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        max-width: 830px;
        height: 800px;
        background-color: ${(props) => props.theme.colors.primaryDark};
        border-radius: 100%;
        transform: translateY(-50%) translateX(-50%);
        z-index: ${elevation.base.default};
    }
    .BubbleHeader-header-circle.wide-bubble {
        max-width: 1150px;
        height: 1150px;

    }

  }
`;

export default BubbleHeader;
