import { useEffect } from "react";

type Scripts =
  | "login-script"
  | "articles-script"
  | "exchanges-script"
  | "meter-script"
  | "delivery-point-info-script"
  | "delivery-point-request-tracking-script"
  | "user-info-script"
  | "contract-script"
  | "invoice-script"
  | "payment-script"
  | "move-in-script"
  | "move-in-button-script"
  | "consumption-script"
  | "coach-conso-script"
  | "adv-cdn";

function getURl(scriptType: Scripts) {
  if (scriptType === "articles-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/articles/widget-articles.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "contract-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/contract/widget-contract.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "consumption-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/consumption/details/consumption-widget.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "delivery-point-info-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/deliverypoint/info/widget-delivery-info.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "delivery-point-request-tracking-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/deliverypointRequests/deliverypoint-requests-widgets.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "exchanges-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/exchanges/widget-exchanges.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "invoice-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/invoice/billing-widget.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "login-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/login/loginWidget.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "meter-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/meter/last-meter-widget.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "move-in-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/movein/move-in-widget.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "move-in-button-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/movein/move-in-button-widget.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "payment-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/payment/payment.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "user-info-script")
    return `${process.env.REACT_APP_URL_TO_WIDGETS}/client/profile/userinformation-widget.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "coach-conso-script")
		return `${process.env.REACT_APP_URL_TO_WIDGETS}/coach_conso/widget-coach-conso.js?v=${process.env.REACT_APP_VERSION}`;
  if (scriptType === "adv-cdn")
    return `https://adv-cdn.net/widgets/production/bundle.js`;

  return null;
}

function useRequestScript(scriptTypes: Array<Scripts>) {
  useEffect(() => {
    scriptTypes.forEach((st) => {
      const dom = document.querySelector(`#${st}`);
      const url = getURl(st);
      if (!dom && url) {
        // add script tag at lazy way
        let link = document.createElement("script");
        link.id = st;
        link.src = url;
        document.head.appendChild(link);
      }
    });
  }, [scriptTypes]);
}

export default useRequestScript;
