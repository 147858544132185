const DASHBOARD = 'dashboard_';
const PAYMENTS = "payments_";

const ID_VALUES = {
    welcomeUser: `${DASHBOARD}welcome-user`,
    userName: `${DASHBOARD}header-user-name`,
    accessMyProfile: `${DASHBOARD}access-my-profile`,
    contracts: `${DASHBOARD}contracts`,
    payments: `${DASHBOARD}payements`,
    consumption: `${DASHBOARD}consumption`,
    billings: `${DASHBOARD}billings`,
    help: `${DASHBOARD}help`,
    dashboard: `${DASHBOARD}dashboard`,
    exchange: `${DASHBOARD}exchange`,
    logout: `${DASHBOARD}logout`,
    menuDesktopButton: `${DASHBOARD}menu-desktop-button`,
    widgetInvoiceButtonDebitInvoice: `${PAYMENTS}widget-invoice-button-debit-invoice`,
    createAccount: `create_account-redirect-button`,
    contractCancellationScreen: `contract_cancellation-screen`,
    alertConactValidation: "alert_contact_validation-screen",
    alertConactValidationSecondParagraphe: "alert_contact_validation-seconde-paragraphe",
    loginScreen: {
        qrCode: "qr-code",
        qrTextDownloadLinkGooglePlay: "qr-text-download-link-google-play",
        qrTextDownloadLinkIos : "qr-text-download-link-ios"
    },
    footer: {
        cgu: "cgu-redirect-link-button",
        userData: "userData-redirect-link-button",
        legalMentions: "legalMentions-redirect-link-button",
        cookies:  "cookies-open-banner-button",
    }
    
};
export default ID_VALUES;
