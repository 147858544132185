//EXTERNAL
import React, { FC, useContext } from "react";
import { useSelector } from "react-redux";
import { css } from "styled-components";
//SDS
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import Word from "saur-design-sys-web/lib/Atoms/Word/Word";
import Button from "saur-design-sys-web/lib/Atoms/Input/Button/Button";
import AppLogo from "saur-design-sys-web/lib/Atoms/AppLogo/AppLogo";
import { device, colors } from "saur-design-sys-web/lib/Theme";
//SSM
import { States } from "saur-states-management";
import { getTransation } from "saur-states-management/lib/i18n";
import { geti18nContext } from "../common/translation";
import { brandParameter } from "saur-states-management/lib/selectors/settingsSelectors";

// INTERNAL 
import CookiesBanner from "../components/CookiesBanner";
import ID_VALUES from "../automated-tests-ids/ids";

type AppFooterProps = {

};

const AppFooter: FC<AppFooterProps> = ( ) => {
	const settingState = useSelector((s: States) => s.settings);
	const state = useSelector((s: States) => s);
	const CguUrl = brandParameter(state, "CguUrl");
	const DataProtectionPolicyUrl = brandParameter(
		state,
		"DataProtectionPolicyUrl"
	);
	const LegalNoticeUrl = brandParameter(state, "LegalNoticeUrl");
	const translation = useContext(geti18nContext());

	return (
		<Container id="footer-app" className="AppFooter">
			<Container className="MainFooterContainer">
				<AppLogo content="footer" />
				<Container className="FooterNavContainer">
						<Button
							mode="basic"
							automatedTestId={ID_VALUES.footer.cgu}
							onClick={(): void => {
								window.open(CguUrl, "_blank");
							}}
						>
							<Word
								className="NavLink"
								value={getTransation((t) => t.navigations.cgu.label)(translation)}
								textStyle="tinyMedium"
								variant="span"
							/>
						</Button>
						<Button
							mode="basic"
							automatedTestId={ID_VALUES.footer.userData}
							onClick={(): void => {
								window.open(DataProtectionPolicyUrl, "_blank");
							}}
						>
							<Word
								className="NavLink"
								value={getTransation((t) => t.navigations.userData.label)(
									translation
								)}
								textStyle="tinyMedium"
								variant="span"
							/>
						</Button>
						<Button
							mode="basic"
							automatedTestId={ID_VALUES.footer.legalMentions}
							onClick={(): void => {
								window.open(LegalNoticeUrl, "_blank");
							}}
						>
							<Word
								className="NavLink"
								value={getTransation((t) => t.navigations.legalMentions.label)(
									translation
								)}
								textStyle="tinyMedium"
								variant="span"
							/>
						</Button>
					<CookiesBanner mode="button"/>
				</Container>
				<Container>
					<Word
						value={getTransation((t) => t.common.copyRight, {
							brand: settingState.params?.BrandFullName || "",
						})(translation)}
						textStyle="xxsRegular"
						variant="span"
					/>
				</Container>
			</Container>
		</Container>
	);
};

export const AppFooterStyles = css`
	.AppFooter {
		background-color: #cde8f7;
		@media ${device.mobile} {
			height: auto;
		}
		@media ${device.tablet} {
			height: 56px;
		}

		.NativeLink {
			display: flex;
			justify-content: center;
		}
		.MainFooterContainer {
			z-index: 2;
			align-items: center;
			justify-content: space-between;
			background-color: ${colors.white};
			padding: 0 6%;
			border-top-right-radius: 30px;
			@media ${device.mobile} {
				height: auto;
				flex-direction: column;
				padding: 12px 0 25px 0;
			}
			@media ${device.tablet} {
				height: 56px;
				flex-direction: row;
				padding: 0 20px;
			}
		}
		.NavLink {
			@media ${device.mobile} {
				margin-right: 0;
				margin-bottom: 10px;
				text-align: center;
			}
			@media ${device.tablet} {
				margin-right: 30px;
				margin-bottom: 0;
				text-align: left;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.FooterNavContainer {
			.sds-button {
				width: auto;
			}

			@media ${device.mobile} {
				flex-direction: column;
				align-items: center;
				margin-top: 20px;
				.sds-button-container {
					margin-right: 0;
				}
			}
			@media ${device.tablet} {
				flex-direction: row;
				margin-top: 0px;
				.sds-button-container {
					margin-right: 30px;
				}
			}
		}
	}
`;

export default AppFooter;
