import React, { FC, useContext } from "react";
import CookiesBannerUI from "saur-design-sys-web/lib/Molecules/CookiesBanner/CookiesBanner";
import CookiesMenuPanel from "saur-design-sys-web/lib/Molecules/CookiesMenuPanel/CookiesMenuPanel";
import { useDispatch, useSelector } from "react-redux";
import { getCookiesSettings } from "saur-states-management/lib/selectors/settingsSelectors";
import {
  settingsActions,
  CookieSetting,
} from "saur-states-management/lib/states/settingsState";

import { geti18nContext } from "../common/translation";
import { States } from "saur-states-management";

import { getTransation } from "saur-states-management/lib/i18n";
import Button from "saur-design-sys-web/lib/Atoms/Input/Button/Button";
import Word from "saur-design-sys-web/lib/Atoms/Word/Word";
import ID_VALUES from "../automated-tests-ids/ids";

type Option = {
  id: number;
  title: string;
  desc: string;
  default: boolean;
  active: boolean;
};

type Props = {
  mode: 'banner' | 'button'
};

function get_cookie(cookieName:string){  
  let targetCookieName = null;
  for (const cookie of document.cookie.split("; ")) {
    const name = cookie.split("=")[0];
    if (name.startsWith(cookieName)) {
      targetCookieName = name;
        break; // Exit the loop if the cookie is found
    }
  }
  return targetCookieName;
}

function delete_cookie( name: string, path: string) {  
  const cookieName =get_cookie( name ) 
  if(cookieName) {     
    document.cookie = cookieName + "=" +
      ((path) ? ";path="+path:"")+
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

const CookiesBanner: FC<Props> = ({mode}) => {
  const dispatch = useDispatch();
  const translation = useContext(geti18nContext());
  const cookieSettings = useSelector(getCookiesSettings) as Array<
    CookieSetting
  >;
  const showBanner = useSelector((state: States) => !state.settings.isCookiesSet);
  const params = useSelector((state: States) => state.settings.params)


  function getBrandWebSiteUrl(): string {
    if (params && params.HomeUrl) {
      return params.HomeUrl
    }
    return ''
  }

  const requiredCookie = { id: 3, rule: "required", accepted: true }


  const [cookiesPanelState, setCookiesPanelState] = React.useState(false);

  function toCookie(option: Option): CookieSetting | null {
    switch (option.id) {
      // case 1 session, deprecated
      case 2:
        // ANALYTIC
        return {
          id: option.id,
          rule: "analytic",
          accepted: option.active,
        };
      case 3:
        return {
          id: option.id,
          rule: "required",
          accepted: true
        }
      default:
        return null;
    }
  }

  function toOption(cookie: CookieSetting): Option | null {
    switch (cookie.rule) {
      // case session, deprecated
      case "analytic":
        return {
          id: 2,
          title: getTransation(
            (t) => t.cookiesConfig.panel.optionAnalytic.title
          )(translation),
          desc: getTransation((t) => t.cookiesConfig.panel.optionAnalytic.desc, { soft: 'Piwik' })(
            translation
          ),
          default: false,
          active: cookie.accepted,
        };
        case "required":
          return {
            id: 3,
            title: getTransation(
              (t) => t.cookiesConfig.panel.requiredCookie.title
            )(translation),
            desc: getTransation((t) => t.cookiesConfig.panel.requiredCookie.desc, { soft: 'Piwik' })(
              translation
            ),
            default: true,
            active: cookie.accepted,
          };
      default:
        return null;
    }
  }

  function getComputedData(): Array<Option> {
    return [requiredCookie, ...cookieSettings].map(toOption).filter((v) => v !== null) as Array<
      Option
    >;
  }

  function acceptAllCookies() {
    setCookiesPanelState(false);
    const nextState = cookieSettings.map((cs) => ({
      ...cs,
      accepted: true,
    }));
    dispatch(settingsActions.updateCookiesSetting(nextState));
  }

  function acceptCustomCookies(values: Array<Option>) {
    const nextState = values.map(toCookie).filter((v) => v !== null && v.rule !== 'required') as Array<
      CookieSetting
    >;
    setCookiesPanelState(false);    
    dispatch(settingsActions.updateCookiesSetting(nextState));

  }

  function rejectAllCookies() {
    setCookiesPanelState(false);
    const nextState = cookieSettings.map((cs) => ({
      ...cs,
      accepted: false,
    }));
    delete_cookie('_pk_ses.','/')
    dispatch(settingsActions.updateCookiesSetting(nextState));
  }

  const optionsData = getComputedData();
  
  return (
    <>
      {(showBanner && mode === 'banner') && (
        <CookiesBannerUI
          automatedTestId="Foo"
          infoText={getTransation((t) => t.cookiesConfig.banner.infoText)(
            translation
          )}
          toggleMenuCookies={() => setCookiesPanelState(true)}
          toggleMenuCookiesLabel={getTransation(
            (t) => t.cookiesConfig.banner.toggleMenuPanelLabel
          )(translation)}
          acceptCookies={acceptAllCookies}
        />
      )}
      { mode === 'button' && (
       <Button
          mode="basic"
          automatedTestId={ID_VALUES.footer.cookies}
          onClick={()=> { setCookiesPanelState(!cookiesPanelState)}}
        >
        <Word
          className="NavLink"
          value={"Cookies"}
          textStyle="tinyMedium"
          variant="span"
        />
      </Button>
      )}
      {cookiesPanelState && optionsData && (
        <CookiesMenuPanel
          OptionsTitleList={getTransation(
            (t) => t.cookiesConfig.panel.OptionsTitleList
          )(translation)}
          contentPanel={getTransation(
            (t) => t.cookiesConfig.panel.contentPanel,
            { weburl: getBrandWebSiteUrl() }
          )(translation)}
          titlePanel={getTransation((t) => t.cookiesConfig.panel.titlePanel)(
            translation
          )}
          alwayActifLabel={getTransation(
            (t) => t.cookiesConfig.panel.alwayActifLabel
          )(translation)}
          rejectAllCookiesLabel={getTransation(
            (t) => t.cookiesConfig.panel.rejectAllCookiesLabel
          )(translation)}
          acceptAllCookiesLabel={getTransation(
            (t) => t.cookiesConfig.panel.acceptAllCookiesLabel
          )(translation)}
          options={optionsData}
          acceptCustomCookies={(v) => acceptCustomCookies(v)}
          rejectAllCookies={rejectAllCookies}
          acceptAllCookies={acceptAllCookies}
          closeMenuPanel={() => {
            setCookiesPanelState(false);
          }}
          policyTitle={getTransation(
            (t) => t.cookiesConfig.panel.policyTitle
          )(translation)}
          policyMessage={getTransation(
            (t) => t.cookiesConfig.panel.policyMessage
          )(translation)}
          policyLink={params.CookiesUrl}
        />
      )}
    </>
  );
};

export default CookiesBanner;
