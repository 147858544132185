import React, { FC, ReactElement, memo } from "react";
import { useSelector } from "react-redux";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import { Brands, ThemeType } from "saur-design-sys-web/lib/BrandsConfig";
import {
  device,
  onlyForDesktop,
  onlyForMobile,
} from "saur-design-sys-web/lib/Theme";
import { States } from "saur-states-management";
import {
  getAccessToken,
  getAuthenticatedUser,
} from "saur-states-management/lib/selectors/authSelectors";
import {
  getLanguage,
  isFeatureEnabled,
} from "saur-states-management/lib/selectors/settingsSelectors";
import { css } from "styled-components";
import MobileMenu from "../components/MobileMenu";
import useShowSectionsByLocationUri from "../hooks/useShowSectionsByLocationUri";
import useWidgetsEventListeners from "../hooks/useWidgetsEventListeners";
import AppFooter from "./AppFooter";
import { AppHeader } from "./AppHeader";

type ContentLoggedWrapperProps = {
  children: ReactElement;
  isDashboard: boolean;
};

type modalMode = "in" | "out" | undefined;

const ContentLoggedWrapper: FC<ContentLoggedWrapperProps> = ({
  children,
  isDashboard,
}) => {
  const [visibleMobileMenu, setVisibleMobileMenu] =
    React.useState<modalMode>(undefined);

  const state = useSelector((s: States) => s);
  const currentVersion = isFeatureEnabled(state, "Portail-Professionnel")
    ? "v2"
    : "v1";
  const isMultiContrats = useSelector((s: States) =>
    isFeatureEnabled(s, "Contrat-MultiContrats")
  );
  const authenticatedUser = useSelector(getAuthenticatedUser);
  const isFamilyMode = authenticatedUser?.isFamilyMode ?? false;
  const accessToken = useSelector(getAccessToken) as string;
  const settingState = useSelector((s: States) => s.settings);
  const currentLang = useSelector(getLanguage) as string;
  const theme = useSelector((s: States) => s.settings.theme) as Brands;

  function toggleMenu(option: modalMode) {
    setVisibleMobileMenu(option);
  }

  function removeVisibleMobileMenu() {
    setVisibleMobileMenu("out");
  }

  const {
    subTitle,
    showActionButtons,
    showContractSection,
    showSectionSubscription,
  } = useShowSectionsByLocationUri();

  useWidgetsEventListeners();

  return (
    <Container
      id="logged-content-wrapper"
      className={`content-logged-wrapper theme-${theme}`}
    >
      <AppHeader
        toggleMenu={toggleMenu}
        menuStatus={visibleMobileMenu}
        showClientProMenu={isFamilyMode}
      />

      <Container
        className="clw-main-content-row"
        onClick={removeVisibleMobileMenu}
      >
        <Container
          className={`clw-main-content ${isDashboard ? "dashboard" : ""}`}
        >
          <Container className="clw-content">
            <ContractSelector
              showContractSection={showContractSection}
              showActionButtons={showActionButtons}
              subTitle={subTitle}
              accessToken={accessToken}
              currentLang={currentLang}
              theme={settingState.theme}
              currentVersion={currentVersion}
              showSectionSubscription={showSectionSubscription}
              isMultiContrats={isMultiContrats}
            ></ContractSelector>
            {children}
          </Container>
        </Container>
      </Container>
      <AppFooter />
      <MobileMenu toggleMenu={toggleMenu} visible={visibleMobileMenu} />
    </Container>
  );
};

interface IProps {
  isMultiContrats: boolean;
  showContractSection?: "show" | "hide";
  showActionButtons?: "hide" | "show";
  subTitle?: "address" | "clientRef";
  accessToken: string;
  currentLang: string;
  theme: string;
  currentVersion?: "v1" | "v2";
  showSectionSubscription?: "show" | "hide";
}

const ContractSelector = memo(
  ({
    isMultiContrats,
    showContractSection,
    showActionButtons,
    subTitle,
    accessToken,
    currentLang,
    theme,
    currentVersion,
    showSectionSubscription,
  }: IProps) => {
   
    return (
      <>
        {isMultiContrats && (
          <Container className="multiContractContainer">
            <contract-widget
              showcontractsection={showContractSection}
              showmodal="hide"
              showactionbuttons={showActionButtons}
              subTitle={subTitle}
              token={accessToken}
              lang={currentLang}
              theme={theme}
              currentversion={currentVersion}
              showsectionsubscription={showSectionSubscription}
            />
          </Container>
        )}
      </>
    );
  }
);

export const ContentLoggedWrapperStyles = css`
  .content-logged-wrapper {
    .multiContractContainer {
      margin-bottom: 20px;
    }
    .clw-background-wave {
      ${onlyForDesktop}
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .clw-background-wave-mobile {
      ${onlyForMobile}
      width: 100%;
      position: absolute;
      bottom: 0;
    }
    .clw-main-content-row {
      position: relative;
      flex: 1;
      flex-grow: 1;
      background-color: ${(props: ThemeType) =>
        props.theme.colors.primaryLight};
      min-height: 100vh;
      @media ${device.mobile} {
        padding-top: 64px;
      }
      @media ${device.tablet} {
        padding-top: 90px;
      }
    }
    .clw-content {
      width: calc(100% - 40px);
      @media ${device.mobile} {
        padding: 30px 20px 20px 20px;
        align-self: auto;
      }
      @media ${device.tablet} {
        padding: 30px 20px 20px 20px;
        max-width: 1063px;
        align-self: auto;
      }
      @media ${device.desktop} {
        align-self: center;
      }
    }
    .clw-main-content {
      background-color: ${(props: ThemeType) =>
        props.theme.colors.primaryLight};
      min-height: 100vh;
      @media ${device.mobile} {
        border-top-left-radius: 0;
        background: url("/assets/bgWave-consumption-mobile.svg") no-repeat;
        background-position: bottom;
      }
      @media ${device.tablet} {
        border-top-left-radius: 60px;
        background: url("/assets/bgWave-consumption-desktop.svg") no-repeat;
        background-position: bottom;
      }
      &.dashboard {
        @media ${device.mobile} {
          border-top-left-radius: 0;
          background: url("/assets/wave-mobile.svg") no-repeat;
          background-position: bottom;
        }
        @media ${device.tablet} {
          border-top-left-radius: 60px;
          background: url("/assets/wave-desktop.svg") no-repeat;
          background-position: bottom;
        }
      }
    }
  }
`;

export default ContentLoggedWrapper;
