import React, { FC, ReactElement } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLanguage } from "saur-states-management/lib/selectors/settingsSelectors";
import { langTypes } from "saur-states-management/lib/i18n";
import Nav from "saur-design-sys-web/lib/Molecules/Nav/Nav";

const CustomLink: FC<{
  to: string;
  activeOnlyWhenExact?: boolean;
  children: ReactElement;
  automatedTestId?: string;
}> = ({ to, activeOnlyWhenExact = false, children, automatedTestId = "" }) => {
  const currentLang = useSelector(getLanguage) as langTypes;

  const finalRoute = `/${currentLang}${to}`;

  const match = useRouteMatch({
    path: finalRoute,
    exact: activeOnlyWhenExact,
  });

  return (
    <Nav isActive={!!match}>
      <Link to={finalRoute} id={automatedTestId}>
        {children}
      </Link>
    </Nav>
  );
};

export default CustomLink;
