import { createTransform } from "redux-persist";
import sessionStorage from 'redux-persist/lib/storage/session'
import configStore, { initialStates, StateName } from "saur-states-management";
import settingsEffetcs from './store/settingsEffetcs'

const localsagas: Array<any> = [settingsEffetcs];

const savedState = (key: StateName, state: any) => {
	const saveds: Array<StateName> = ['settings', 'auth', 'account', 'consumer'];
	if (state && saveds.includes(key)) {
		return {
			...initialStates[key],
			...state,
		}
	}
	if (state && !saveds.includes(key)) {
		return initialStates[key]

	}
	if (!state) {
		return initialStates[key]
	}
}

const resetStateStatus = (state: any) => ({
	...state,
	errorMessage: null,
	loading: false,
	loaded: false
})

const filterInCommingStateToStorage = (key: StateName, state: any) => {
	switch (key) {
		case 'consumer':
			return resetStateStatus(state)
		case 'account':
			return resetStateStatus(state)
		case 'auth':
			return resetStateStatus(state)
		case 'settings':
			return {
				...state,
				loading: false
			}
		default:
			return state
	}
}


const GeneralSettings = createTransform(
	// transform state on its way to being serialized and persisted.
	(inboundState: any, key: StateName) => {
		const next = filterInCommingStateToStorage(key, inboundState)
		// convert mySet to an Array.
		return savedState(key, next)
	},
	(outboundState, key: StateName) => {
		// convert mySet back to a Set.
		return savedState(key, outboundState)
	},
);

// custom config for react-native with AsyncStorage
const persistConfig = {
	key: "root",
	storage: sessionStorage,
	transforms: [GeneralSettings]
};

// Initalize the redux store
const { store, persistor, PersistGate } = configStore(
	// [reduxLogger],
	[],
	// @ts-ignore
	persistConfig
);

export { store, persistor, PersistGate, localsagas };
