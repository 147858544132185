import { createGlobalStyle } from "styled-components";
import { DesktopMenuStyles } from "./components/DesktopMenu";
import { MobileMenuStyles } from "./components/MobileMenu";
import { AccountIconStyles } from "./components/Icons/AccountIcon";
import { AppFooterStyles } from "./templates/AppFooter";
import { AppHeaderStyles } from "./templates/AppHeader";
import { BubbleHeaderStyles } from "./templates/BubbleHeader";
import { ContentLoggedWrapperStyles } from "./templates/ContentLoggedWrapper";
import { ContentUnloggedWrapperStyles } from "./templates/ContentUnloggedWrapper";
import { LoaderScreenStyles } from './components/Navigation'

const AppStyles = createGlobalStyle`
.sds-reset-style{
    ${DesktopMenuStyles}
    ${MobileMenuStyles}
    ${AccountIconStyles}
    ${AppFooterStyles}
    ${AppHeaderStyles}
    ${BubbleHeaderStyles}
    ${ContentLoggedWrapperStyles}
    ${ContentUnloggedWrapperStyles}
    ${LoaderScreenStyles}
}
`;

export default AppStyles;
