import { Brands } from "saur-design-sys-web/lib/BrandsConfig";

function getPath(brand: Brands) {
    if (brand === "atlantic-eau") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/atlantic-eau.css`
    if (brand === "sem") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/sem.css`
    if (brand === "eau-de-garonne") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/eau-de-garonne.css`
    if (brand === "saur") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/saur.css`
    if (brand === "accm") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/accm.css`
    if (brand === "cer") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/cer.css`
    if (brand === "cgsp") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/cgsp.css`
    if (brand === "cise-reunion") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/cise-reunion.css`
    if (brand === "grand-lac") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/grand-lac.css`
    if (brand === "saint-affo") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/saint-affo.css`
    if (brand === "saint-martin") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/saint-martin.css`
    if (brand === "sateg") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/sateg.css`
    if (brand === "se3r") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/se3r.css`
    if (brand === "sepig") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/sepig.css`
    if (brand === "sudeau") return `${process.env.REACT_APP_HOTFIX_CSS_URL}/sudeau.css`
    return null
}


function setHotFixCss(brand: Brands) {
    const dom = document.querySelector(`#${brand}-hotfix-css`)
    const url =  getPath(brand);

    if (!dom && url) {
        // add script tag at lazy way
        let link = document.createElement('link')
        link.id = `${brand}-hotfix-css`;
        link.href = url;
        link.rel = "stylesheet"
        document.head.appendChild(link)
    }
        
}

export default setHotFixCss;
