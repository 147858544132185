import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { urlsConfig } from "saur-states-management";
import ErrorBoundary from "saur-states-management/lib/components/ErrorBoundary";
// for IE11

urlsConfig.setURls({
  KEYCLOAK_URL: process.env.REACT_APP_KEYCLOAK_URL,
  BASE_URL: process.env.REACT_APP_BACK_END_URL,
  ADMIN_BASE_URL: process.env.REACT_APP_ADMIN_URL,
  KEYCLOAK_CLIENT_ID: process.env.REACT_APP_KEYCLOACK_CLIENT_ID,
  KEYCLOAK_SCOPE: process.env.REACT_APP_KEYCLOACK_SCOPE,
  DELIVERYPOINT_BASE_URL: process.env.REACT_APP_DELIVERYPOINT_URL,
  HISTORY_INTERVENTION: process.env.REACT_APP_HISTORY_INTERVENTION_API,
  CONSUMER_BASE_URL: process.env.REACT_APP_CONSUMER_URL
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary widgetName="WebApp" featureId={0} >
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
