import React from "react";
import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import Container from "saur-design-sys-web/lib/Atoms/Container/Container";
import {
  elevation,
  onlyForDesktop,
  onlyForMobile,
  device
} from "saur-design-sys-web/lib/Theme";
import { States } from "saur-states-management";
import { getLanguage } from "saur-states-management/lib/selectors/settingsSelectors";
import { createGlobalStyle } from "styled-components";
import useRequestScript from "../common/useRequestScript";

const DeliveryPointRequestTracking = () => {
  useRequestScript(["delivery-point-request-tracking-script"]);

  const lang = useSelector(getLanguage) as string;
  const theme = useSelector((state: States) => state.settings.theme);

  const { search } = useLocation();
  const requestId = new URLSearchParams(search).get("id"); //  get from query params ?id={connectionRequestId}

  return (
    <Container>
      <Styles />

      <Container className="sds-reset-style screen-deliverypoint-request-tracking">
        <widget-deliverypoint-requests-tracking
          runTime="lite"
          lang={lang}
          theme={theme}
          requestid={String(requestId)}
          data-testid="widget-deliverypoint-requests-tracking"
        />
      </Container>
      <img
        className="CreateAccountScreen-background mobile"
        src="/assets/bgWave-payment-result-mobile.svg"
        alt="background-wave"
      />
      <img
        className="CreateAccountScreen-background desktop"
        src="/assets/bgWave-payment-result-desktop.svg"
        alt="background-wave"
      />
    </Container>
  );
};

const Styles = createGlobalStyle`
.sds-reset-style {
  .screen-deliverypoint-request-tracking {
    padding: 20px;
    z-index: ${elevation.sup.top};
    @media ${device.tablet} {
      padding: 60px 0;
    }
  }
  .CreateAccountScreen-background {
    position: fixed;
    bottom: 0;
    &.mobile {
      ${onlyForMobile}
    }
    &.desktop {
      ${onlyForDesktop}
      z-index: ${elevation.sup.under};
      height: 100%;
      width: 100%;
    }
  }
  .content-unlogged-wrapper {
    min-height: 90vh !important;
    background-color: rgb(247, 249, 251);
    min-width: fit-content;
  }
  .AppFooter{
    z-index: ${elevation.sup.top};
    position: relative;
    width: 100%;
    height: fit-content;
    @media ${device.tablet} {
      height: 10vh !important;
    } 
    .MainFooterContainer {
        height: fit-content;
      @media ${device.tablet} {
        height: 10vh !important;
      } 
    }
  }
  .sds-m-cookiebanner{
    z-index: ${elevation.high.top};
  }
}

`;

export default DeliveryPointRequestTracking;
